import React, { Component } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { postData, AjaxConfirm, toastMessageShow, getApplicantId, getMemberId, lockObject, unlockObject } from 'service/common.js';
import '../scss/components/admin/crm/pages/sales/opportunity/OpportunityDetails.scss'
import { Icon ,Modal,Button} from '@salesforce/design-system-react';
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import SystemAlertPopup from './SystemAlertPopup';

class ScheduleStatusPath extends React.Component {

    /**
     * setting the initial prop values
     * @param {*} props
     */
    constructor(props) {
        super(props);
        this.state = {
            selected_status_id: false,
            selected_status_label: '',
            status_options: [],
            showAlert:false,
            alertMessage:''
        }
    }

    /**
     * Handling the status change in closed popup
     */
    onSelect = (value, key) => {
        this.setState({ [key]: value });
    }

    /**
     * setting state when user clicks on any status (excluding the modal status)
     */
    selectStatus = (val) => {
        if (val.value === this.props.status || (val.value == 7 && (this.props.status_label === "Completed" || this.props.status_label === "Cancelled"))) {
            return false;
        }
        this.setState({ selected_status_label: val.label, selected_status_id: val.value });
    }

    /**
     * when status change is requested
     */
    updateShiftStatus = () => {
        if (!this.state.selected_status_id) {
            toastMessageShow("Please select status", "e");
            return false;
        }

        if (this.state.selected_status_label == "Scheduled") {
            return false;
        }
        if (this.state.selected_status_label == "Start" && this.props.status >= 4) {
            return false;
        }
        if (this.state.selected_status_label == "Complete" && this.props.status >= 5) {
            return false;
        }
        if (this.state.selected_status_label == "Complete" && this.props.status != 4) {
            toastMessageShow("Please start the shift to complete it", "e");
            return false;
        }

        var status = '';
        var label = '';
        var label_small = '';
        if(this.state.selected_status_id == 4) {
            status = 4;
            label = 'Start';
            label_small = 'start';
        }
        else if(this.state.selected_status_id == 5) {
            status = 5;
            label = 'Complete';
            label_small = 'complete';
        }

        // lock object
        lockObject(this.props.id, 'shift');

        const msg = "Are you sure you want to " + label_small + " the shift: " + this.props.shift_no + "?";
        const confirmButton = label + ` Shift`;
        AjaxConfirm({ reason_drop: '', reason_note: '', id: this.props.id, status: status, applicant_id: getApplicantId(), member_id: getMemberId() }, msg, `recruitment/Recruitment_member/update_shift_status`, { confirm: confirmButton, heading_title: confirmButton }).then(result => {
            if (result.status) {
                this.props.get_shift_details(this.props.id);
                this.setState({ selected_status_label: "", selected_status_id: "", reason_note: "", reason_drop: ""})
                toastMessageShow(result.msg, "s");
            } else {

                if(result.error!='API ERROR'&&result.timeElaspe)
                {
                    this.setState({alertMessage:result.error,showAlert:true,shiftStatus:status})
                }
                else{
                    toastMessageShow(result.error, "e");
                }
            }
            
            // unlock object
            unlockObject(this.props.id, 'shift');
        })
    }

    /**
     * mounting all the components
     */
    componentDidMount() {
        this.get_shift_status_portal();
    }

    /**
     * fetching the shift statuses
     */
    get_shift_status_portal = () => {
        var req = {id: this.props.shift_id, applicant_id: getApplicantId(), member_id: getMemberId()}
		postData("recruitment/Recruitment_member/get_shift_status_portal", req).then((res) => {
			if (res.status) {
				this.setState({
					status_options: (res.data)?res.data:[]
				})
			}
		});
    }

    /**system alert close */

    closeAlert=()=>{
        this.setState({showAlert:false})
    }
    /**copy to clipboard */
    copyToClipboard(){
        navigator.clipboard.writeText('1300 962 468');
        this.closeAlert();
    }

    /**
     * rendering components
     */
    render() {
        var status_label = this.props.status_label;
        var selected_status_close = false;

        if (this.state.selected_status_label == "Closed" ||
            this.state.selected_status_label == "Cancelled" ||
            this.state.selected_status_label == "Completed") {
            selected_status_close = true;
        }

        return (
            <div className="slds-path ">
                <div className="slds-grid slds-path__track">
                    <div className="slds-grid slds-path__scroller-container">
                        <div className="slds-path__scroller" role="application">
                            <div className="slds-path__scroller_inner sdls_custom_path">
                                <ul className="slds-path__nav" role="listbox" aria-orientation="horizontal">

                                    {this.state.status_options.map((val, index) => {
                                        var genral_className = "";
                                        // pre-highlighting existing status from db
                                        if (parseInt(val.value) === parseInt(this.props.status)) {
                                            var genral_className = "slds-is-current slds-is-active";
                                        }

                                        var complated_class_name = "";
                                        var lost_classname = "";
                                        var com_label = val.label;
                                        var selected_status_class = "slds-is-incomplete";

                                        // marking all previous statuses as green ticked
                                        if (parseInt(val.value) < parseInt(this.props.status)) {
                                            complated_class_name = "slds-is-complete";
                                        }

                                        // when user changes the status, we need to highlight that
                                        // Also consider final statuses for Closed status
                                        if(this.state.selected_status_label === val.label) {
                                            selected_status_class = "slds-is-current";
                                            complated_class_name = "";
                                        }
                                        if (selected_status_close && val.label == "Closed") {
                                            selected_status_class = "slds-is-current";
                                            complated_class_name = "";
                                        }

                                        // showing Start as Started
                                        if (val.label == "Start" && this.props.status >= 4) {
                                            com_label = "In Progress";
                                        }
                                        // showing Complete as Completed
                                        if (val.label == "Complete" && this.props.status >= 5) {
                                            com_label = "Completed";
                                        }

                                        // Marking the final stage as red
                                        if (val.label === "Closed" && status_label === "Cancelled") {
                                            lost_classname = "slds-is-lost slds-is-active slds-is-current";
                                            com_label = "Cancelled";
                                        }

                                        // Marking the final stage as green
                                        if (val.label === "Closed" && status_label === "Completed") {
                                            complated_class_name = "slds-is-won slds-is-active slds-is-current";
                                            com_label = "Completed";
                                        }

                                        var className = classNames('slds-path__item', genral_className, selected_status_class, complated_class_name, lost_classname);

                                        return (
                                            <li key={index + 1} className={className} role="presentation" onClick={() => this.selectStatus(val)}>
                                                <a aria-selected="true" className="slds-path__link" href="javascript:void(0);" id="path-1" role="option" tabindex="0">
                                                    <span className="slds-path__stage">
                                                        <svg className="slds-icon slds-icon_x-small" aria-hidden="true">
                                                            <use href="/assets/salesforce-lightning-design-system/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                                                        </svg>
                                                        <span className="slds-assistive-text">Current Stage:</span>
                                                    </span>
                                                    <span className="slds-path__title">{com_label}</span>
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {this.props.status < 5 ?
                    <div className="slds-grid slds-path__action">
                        <span className="slds-path__stage-name"></span>
                        <button className="slds-button slds-button_brand slds-path__mark-complete" onClick={this.updateShiftStatus}>
                            <svg className="slds-button__icon slds-button__icon_left" aria-hidden="true">
                                <use href="/assets/salesforce-lightning-design-system/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                            </svg>Mark Status as Current</button>
                    </div> : ''}
{this.state.showAlert&&(<SystemAlertPopup  
closeAlert ={this.closeAlert}
shiftStatus={this.state.shiftStatus}
alertMessage={this.state.alertMessage}/>)}
                </div>


            </div>
        );
    }

}

export default ScheduleStatusPath