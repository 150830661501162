import React, { Component } from 'react';
import 'react-select-plus/dist/react-select-plus.css';
import moment from "moment";
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { postData, css, toastMessageShow, getApplicantId, getMemberId } from 'service/common.js';
import {getAddressForViewPage} from '../admin/oncallui-react-framework/services/common';
import { connect } from 'react-redux'

import PropTypes from 'prop-types';

import EditMyDetailsModal from './EditMyDetailsModal';
import EditMyDetailsForMemberModal from './EditMyDetailsForMemberModal';
import '../admin/scss/details.scss'
import DocumentCard from './member/document/DocumentCard';
import {
    IconSettings,
    PageHeaderControl,
    ButtonGroup,
    Button,
    Icon,
    PageHeader,
    Tabs,
    TabsPanel,
    Avatar
} from '@salesforce/design-system-react';

import { SplitContainer } from './salesforce/lightning/DetailsComponent';
import { DetailsTitle } from './salesforce/lightning/DetailsTitleCard';
import DefaultPic from './oncallui-react-framework/object/DefaultPic';
import { setAvatar } from '../../service/common';

class MyDetails extends Component {
    constructor(props) {
        super(props);
      
        this.state = {
            activeTab: 'related',
            account_person: {},
            owner: {},
            contacts: [],
            opportunityStage: [],
            opportunity_status: '',            
            address_details: '',
            applicant_id: getApplicantId(),
            member_id: getMemberId(),
            avatar:''
        }
    }

    get_applicant_details = (id) => {
        postData('recruitment/Recruitment_member/get_applicant_details', { applicant_id: id }).then((result) => {
            if (result.status) {                
                this.setState(result.data, () => {
                    let address ='';
                    if(result.data.applicant_address){
                        if(result.data.applicant_address.street!='' && result.data.applicant_address.street!=undefined 
                         && result.data.applicant_address.city!='' && result.data.applicant_address.city!=undefined
                        && result.data.applicant_address.postal!='0000' && result.data.applicant_address.postal!=undefined ){
                            address = result.data.applicant_address.street +','+  result.data.applicant_address.city +','  +result.data.applicant_address.postal +','  +result.data.applicant_address.state_name;
                        }
                       
                        this.setState({unit_number : result.data.applicant_address.unit_number,
                                        street:result.data.applicant_address.street,
                                         city:result.data.applicant_address.city,
                                         postal:result.data.applicant_address.postal,
                                         state:result.data.applicant_address.state,
                                         primary_address:result.data.applicant_address.primary_address});
                    }
                    else{
                        this.setState({unit_number : '',
                            street:'',
                             city:'',
                             postal:'',
                             state:'',
                             primary_address:''});
                    }

                    this.setState({
                        address_details : address,
                        applicant_address:result.data.applicant_address,  
                        is_manual_address: result.data.applicant_address.is_manual_address,
                        manual_address: result.data.applicant_address.manual_address
                    });
                    this.setState(result.data, () => {});
                 });
            } else {
                toastMessageShow('something went wrong', "e");
            }
        });
    }

    get_applicant_member_details_by_id = () => {
        let req = {};
        if(this.state.applicant_id && this.state.applicant_id!='0'){
            req = {applicant_id: this.state.applicant_id}
        }else{
            req = {member_id: this.state.member_id}
        }        

        postData('recruitment/Recruitment_member/get_applicant_member_details_by_id', req).then((result) => {
            if (result.status) {
                let address ='';
            
                if(result.data.primary_address){
                    if(result.data.street!='' && result.data.street!=undefined 
                     && result.data.suburb!='' && result.data.suburb!=undefined
                    && result.data.postcode!='0000' && result.data.postcode!=undefined ){
                        address = result.data.street +','+  result.data.suburb +','  +result.data.postcode +','  +result.data.state_name;
                    }

                    this.setState({
                        street:result.data.street,
                        suburb:result.data.suburb,
                        postal:result.data.postcode,
                        state:result.data.state_name});
                }
              
                this.setState({
                    address_details : address, 
                    unit_number: result.data.unit_number, 
                    is_manual_address: result.data.is_manual_address,
                    manual_address: result.data.manual_address
                });
                setAvatar(result.data.avatar);
                this.setState(result.data, () => {});
            }
            return result;
        });
    }
    componentDidMount() {
        if(this.state.member_id>0){
            this.get_applicant_member_details_by_id();
           
        }
         else if (this.state.applicant_id && this.state.applicant_id!='0'){
            this.get_applicant_details(getApplicantId());
        }
    }
    closeModal = (status, req_from) => {
        this.setState({ openCreateModal: false });
        if (status) {
            if(this.state.member_id>0){
                this.get_applicant_member_details_by_id();
               
            }else{
                this.get_applicant_details(getApplicantId());
            }
         // req_from!='member' ? this.get_applicant_details(getApplicantId()) : this.get_applicant_member_details_by_id();
        }
    }
    /**
   * Renders the details tab
   */
    renderDetailsTabContent() {
        let setPhoneNumber = 'N/A';
        if(this.state.phone){
            setPhoneNumber = '+61 ' + this.state.phone;
        }else if(this.state.applicant_phone){
            setPhoneNumber = '+61 ' + this.state.applicant_phone; 
        }

        let details = [
            {
                label: 'First Name',
                value: this.state.firstname || 'N/A',
                editIcn: false,
            },
            {
                label: 'Last Name',
                value: this.state.lastname || 'N/A',
                editIcn: false,
            },
            {
                label: 'Middle Name',
                value: this.state.middlename || 'N/A',
                editIcn: false,
            },
            {
                label: 'Previous Name',
                value: this.state.previous_name || 'N/A',
                editIcn: false,
            },
            {
                label: 'Date of Birth',
                value:  this.state.date_of_birth && moment(this.state.date_of_birth).isValid() ? moment(this.state.date_of_birth).format("DD/MM/YYYY") : 'N/A',
                editIcn: false,
            },
            {
                label: 'Phone',
                value: setPhoneNumber,
                editIcn: false,
            },
            
            {
                label: 'Email',
                value:  this.state.applicant_email ||this.state.username || 'N/A',
                editIcn: false,
            },           
            {
                label: 'Primary Address',
                value: this.state.is_manual_address==1 ? getAddressForViewPage(this.state.manual_address, this.state.unit_number) : this.state.applicant_address && this.state.applicant_address.street ? 
                <ul>
                    <li>
                    {this.state.applicant_address.unit_number ? this.state.applicant_address.unit_number+', ' : ''}
                    {this.state.applicant_address.street ? this.state.applicant_address.street+', ' : ''}
                    {this.state.applicant_address.city ? this.state.applicant_address.city+' ' : ''} 
                    {this.state.applicant_address.state ? this.state.applicant_address.state_name+' ' : ''}
                    {this.state.applicant_address.postal ? this.state.applicant_address.postal+' ' : ''} </li>
                </ul> 
                : this.state.primary_address ? <ul>
                <li>{this.state.unit_number ? this.state.unit_number+', ' : ''}
                {this.state.street ? this.state.street+', ' : ''}
                {this.state.suburb ? this.state.suburb+' ' : ''} 
                {this.state.state ? this.state.state_name+' ' : ''}
                {this.state.postcode ? this.state.postcode+' ' : ''} </li>
              </ul>  : 'N/A', 
                editIcn: false,              
            },
        ]
       // otherDetails will be only shown when the user is member
        const otherDetails = [
            {
                label: 'Transport',
                value: this.state.transport_selection && this.state.transport_selection.length>0 ? this.state.transport_selection.map((ele, i) => [
                    i > 0 && ", ",
                    ele.label
                ]) : 'N/A',
                editIcn: false,
            },
            {
                label: 'Likes',
                value: this.state.like_selection && this.state.like_selection.length>0 ? this.state.like_selection.map((ele, i) => [
                    i > 0 && ", ",
                    ele.label
                ]) : 'N/A',
                editIcn: false,
            },
            {
                label: 'Languages',
                value: this.state.language_selection && this.state.language_selection.length>0 ? this.state.language_selection.map((ele, i) => [
                    i > 0 && ", ",
                    ele.label
                ]) : 'N/A',
                editIcn: false,
            },
            {
                label: 'Hours per week',
                value: this.state.hours_per_week || 'N/A',
                editIcn: false,
            },
            {
                label: 'Experience (In years)',
                value: this.state.mem_experience || 'N/A',
                editIcn: false,
            },
            {
                label: 'Max distance to travel (In kms)',
                value: this.state.max_dis_to_travel || 'N/A',
                editIcn: false,
            },
        ]
            if(this.state.member_id>0)
            {
                details=details.concat([...otherDetails]);
            }    
        return (
            <DetailsTitle 
                details={details}
            />
        )
    }

    /**
     * Rendering the related tab
     */
    renderRelatedTab() {
        if(this.state.loading == true) {
            return <React.Fragment />;
        }

        return (
            <Tabs id="slds-related-tab">
                <TabsPanel label="Related">
                    <div className="container-fluid m-0 pl-1 pr-1">
                        <div class="slds-scrollable_y" style={{"height":'100vh','display': 'initial'}}>
                            {this.renderDocumentCard()}
                        </div>
                    </div>
                </TabsPanel>
            </Tabs>
        )
    }

    /**
     * Renders the document card
     */
    renderDocumentCard() {
        var id = this.state.member_id;
        var applicant_id = this.state.applicant_id;
        return (
            <DocumentCard
                ref="memberDocument"
                member_id={id}
                applicant_id={applicant_id}
                isMobile={this.props.isMobile}
            />
        )
    }

    /**
     * Rendering the details tab
     */
    renderDetailsTab() {
        if(this.state.loading == true) {
            return (
            <React.Fragment></React.Fragment>
            )
        }

        return (
            <Tabs id="slds-details-tab">
                <TabsPanel label="Details">
                    <div className="container-fluid m-0 pl-1 pr-1">
                        <div class="slds-scrollable_y" style={{"height":'100vh','display': 'initial'}}>
                            {this.renderDetailsTabContent()}
                        </div>
                    </div>
                </TabsPanel>
            </Tabs>
        )
    }

    actions = () => (
        <React.Fragment>
            <PageHeaderControl>
                <ButtonGroup variant="list" id="button-group-page-header-actions">
                    <Button label="Edit" onClick={e => {e.preventDefault();this.setState({ openCreateModal: true, selectedApplicantId: this.state.applicant_id })}} />                    
                    {/* <Button label="Edit" /> */}
                </ButtonGroup>
            </PageHeaderControl>
        </React.Fragment>
    );

    /**
     * Render Page Header
     */
    renderPageHeader = () => {
        return (            
            <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                <PageHeader
                    icon={
                        this.state.avatar && <Avatar
                        assistiveText={{ icon: 'Avatar image' }}
                        imgSrc={this.state.avatar || DefaultPic}
                        imgAlt="Profile Pic"
                        size="medium"
                        title={false}
                    /> || 
                        <Icon
                            assistiveText={{ label: 'User' }}
                            category="standard"
                            name="lead"
                        />
                    }
                    onRenderActions={this.actions}
                    title={this.state.applicant_name ? this.state.applicant_name : this.state.fullname}
                    variant="record-home"
                    className="mydetails"
                />
            </IconSettings>                
        );
    }

    /**
     * Render Modal
     * - Edit Details
     */
    renderModal = () => {
        return (
            <>


             {         
                (this.state.openCreateModal && this.state.applicant_id!="0" ?  
                    <EditMyDetailsModal
                        applicantId={this.state.selectedApplicantId}
                        showModal={this.state.openCreateModal}
                        closeModal={this.closeModal}
                        applicant_phone={ this.state.phone || this.state.applicant_phone || ''}
                        applicant_details={this.state.address_details  }
                        applicant_address = {this.state.applicant_address}
                        is_applicant_only={this.state.member_id>0 ?false:true}
                        address={this.state.address_details}
                        unit_number = {this.state.unit_number}
                        {...this.state}
                        is_only_member= {this.state.applicant_id !='0' ? false: true}
                        avatar={this.state.avatar}
                    /> :  this.state.openCreateModal && this.state.applicant_id=="0" ? <EditMyDetailsForMemberModal
                    member_id={this.state.member_id}
                    person_id={this.state.person_id}
                    showModal={this.state.openCreateModal}
                    closeModal={this.closeModal}
                    phone={this.state.phone}
                    applicant_phone={ this.state.phone || this.state.applicant_phone || ''}
                    applicant_details={this.state.address_details  }
                    applicant_address = {this.state.applicant_address}
                    address={this.state.address_details}
                    address_details={this.state.address_details}
                    unit_number = {this.state.unit_number}
                    avatar={this.state.avatar}
                    {...this.state}
                /> : '')
                
            }              
        </>
        );
    }

    render() {
        const styles = css({
            root: {
                fontFamily: "Salesforce Sans, Arial, Helvetica, sans-serif",
                marginRight: -15,
            }
        })
        return (
            <React.Fragment>
                <div className="container-fluid fixed_size">
                    <div className="slds slds-grid slds-grid_horizontal" style={styles.root}>
                        <div className="slds-col col-lg-12 custom_page_header max-width-res">
                            {this.renderPageHeader()}
                            {this.renderDetailsTab()}
                          {/*   <SplitContainer
                                leftColContent={this.renderRelatedTab()}
                                rightColContent={this.renderDetailsTab()}
                            /> */}
                        </div>
                        {this.renderModal()}
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

ReactTable.PropTypes = {
    defaultFiltered: PropTypes.object
}
const mapStateToProps = state => {
    const { pathname } = window.location
    return {
        pathname,
        isMemberMenu: state.CommonReducer.isMember
    }
}
const mapDispatchtoProps = () => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(MyDetails);
