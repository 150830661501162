import React, { Component } from 'react';

import { connect } from 'react-redux'

import { postData, logout, getFullName, getPermission, getApplicantId, getMemberId, toastMessageShow, css } from 'service/common.js';

import { setNotificationToggel, setNotificationHeaderAlert, setNotificationImailAlert, setToggleInfographicSidebar, dismissNotificationAlert, markAllAsRead, updateNotificationAsReaded, getUnreadNotificationCount } from './notification/actions/NotificationAction.js';

import WebsocketUserConnectionHandleData from './externl_component/WebsocketUserConnectionHandleData'

import Icon from '@salesforce/design-system-react/lib/components/icon';
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import GlobalHeader from '@salesforce/design-system-react/lib/components/global-header';
import GlobalHeaderNotifications from '@salesforce/design-system-react/lib/components/global-header/notifications';
import GlobalHeaderProfile from '@salesforce/design-system-react/lib/components/global-header/profile';
import Popover from '@salesforce/design-system-react/lib/components/popover';
import GlobalMenu from '../admin/GlobalMenu';

import '../admin/scss/details.scss';

import moment from 'moment-timezone';
import Spinner from '@salesforce/design-system-react/lib/components/spinner';
import { ROUTER_PATH } from '../../config.js';
import { Avatar } from '@salesforce/design-system-react';
import DefaultPic from './oncallui-react-framework/object/DefaultPic.js';
import { getAvatar } from '../../service/common.js';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permission: (getPermission() == undefined) ? [] : JSON.parse(getPermission()),
            notifications: [
                {
                    action: 'mentioned you',
                    avatar: 'avatar2',
                    comment:
                        '@jrogers Could I please have a review on my presentation deck',
                    id: 1,
                    name: 'Val Handerly',
                    timePosted: '10 hours ago',
                    unread: true,
                    showMemberTab: false,
                    isActiveMember: true,
                    isLeftInnerSidebarOpen: false,
                }
            ],
            NotificationHeaderData: [],
            prevNotificationData: "",
            redirectTo: '',
            IsAllRead: this.props.IsAllRead,
            page: 0,
            pageSize: 5,
            notificationFeedEnd: false,
            isLoading: false,
            member_id: getMemberId(),
            applicant_id: getApplicantId(),
            notification_unread_count: 0
        }
    }

    componentDidMount() {
        let applicant_id = this.state.applicant_id;
        let member_id = this.state.member_id;
        if(applicant_id && applicant_id!="undefined"){
            this.checkTheApplicationIsMember(applicant_id);
        }else{
            this.setState({showMemberTab : true,member_id: member_id,isActiveMember: true }, () => {
                this.props.setMemberFlag();
                this.notificationAlert();
            });
        }
       
    }

    imailNotification() {
        postData('admin/notification/get_imail_notification', {}).then((result) => {
            if (result.data) {
                this.props.imailnotificationalert(result.data);
            }
        });
    }

    handleScroll = (event) => {
        if(this.state.notificationFeedEnd == true || this.state.isLoading == true) {
            return false;
        }
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if (scrollHeight - Math.round(scrollTop) === clientHeight) {
            this.notificationAlert();
        }
    };

    notificationAlert() {
        postData('admin/notification/get_notification_list_for_member', {member_id: this.state.member_id, applicant_id: this.state.applicant_id, pageSize : this.state.pageSize, page : this.state.page, popup: true}).then((result) => {
            if (result.data) {
                this.props.notificationheaderalert(result);
               //Stop the scroll once notification is ended
                if((this.props.NotificationHeaderData).length == 0) {
                    this.setState({notificationFeedEnd : true, isLoading: false})
                    return false;
                }
                else if((this.state.NotificationHeaderData).length > 0 ) {
                    let myarr = this.state.prevNotificationData;
                    this.props.NotificationHeaderData.forEach(function (item) {
                        myarr.push(item);
                    });

                    this.setState({ NotificationHeaderData: this.state.prevNotificationData, isLoading: true}, () => {

                        this.setState({ prevNotificationData: this.state.prevNotificationData, page : this.state.page + 1, isLoading: false });
                    });
                } else {
                    this.setState({ NotificationHeaderData: this.props.NotificationHeaderData, page : this.state.page + 1,
                        prevNotificationData: this.props.NotificationHeaderData});
                }

            }
        });
        this.get_unread_notification_count_for_member();
    }

    get_unread_notification_count_for_member() {
        this.props.getUnreadNotificationCountact({member_id: this.state.member_id, applicant_id: this.state.applicant_id});       
    }
     /**
     * Dismiss Notification
     * @param {*} index
     * @param {*} index
     */
    dismissNotification = (id, index) => {
        this.props.onClickDismissNotification({ notification_id: id, index: index });
        this.setState({ NotificationHeaderData: this.props.NotificationHeaderData })
    }

    notificationRedirect = (item, index, url) => {
        //Redirect directly if notification already read by user
        if(item.unread === "0") {
            window.location = url;
            return false;
        }
        this.props.notificationRedirect({ notification_id: item.id, index: index, url: url });
    }
    markAllNotificationAsRead = (id) => {
        this.props.onClickMarkAllAsRead({member_id: id});
        this.setState({ NotificationHeaderData: this.props.NotificationHeaderData })
        this.props.getUnreadNotificationCountact({member_id: this.state.member_id, applicant_id: this.state.applicant_id});
    }

    imailCount = () => {
        var count = this.props.external_imail_count + this.props.internal_imail_count;
        if (count > 0)
            return <i>{count}</i>;
    }

    checkTheApplicationIsMember(id){
        postData('recruitment/Recruitment_member/check_the_applicant_is_member', { applicant_id: id }).then((result) => {
            if (result.status) {
                if(result.data.applicant_member_info){
                    const memberData = result.data.applicant_member_info[0];
                    const isActive = memberData.status === "1" ? true : false;
                    this.setState({showMemberTab : true,member_id: result.data.applicant_member_info[0].id,isActiveMember: isActive }, () => {
                        this.props.setMemberFlag();
                        this.notificationAlert();
                    });
                }

            } else {
                toastMessageShow('something went wrong', "e");
            }
        });
    }

    renderProfileContent = () => {
        return <>
                <div class="slds-theme_shade slds-p-left_small">Options</div>
                    <div id="header-profile-custom-popover-content">
                        <div className="slds-m-horizontal_small slds-p-vertical_x-small">
                            <div className="slds-tile slds-tile_board slds-m-horizontal_small">
                                <div className="slds-tile__detail">
                                    <ul>
                                        <li><a href="javascript:void(0)" onClick={() => logout()}>Log Out</a></li>
                                        {/* <li><Link to="/admin/update_pin">Update Pin</Link></li>
                                        <li><Link to="/admin/update_password">Update Password</Link></li>
                                        <li><Link to="/admin/update_password_recovery_email">Update Password Recovery Email</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                </div>
            </>
    }

    renderHeaderNotificationsContent = (NotificationHeaderData) => {
        const styles = css({
            hyperlink: {
                color: 'rgb(0, 112, 210)'
            },
            headTitle: {
                fontWeight: 'inherit',
                fontSize: '1rem'
            },
            para: {
                lineHeight: '1rem',
                fontSize: '.825rem'
            },
            notification: {
                position: "absolute",
                top: "calc(100% + -17px)",
                right: "0px",
            }

        });

        return (
            <React.Fragment>
                {
                    NotificationHeaderData.length === 0 ?
                        <div class="view-all-div">
                            <span className="slds-truncate"><a style={styles.hyperlink} className="reset" title="View all notification" href={ROUTER_PATH + "app/notification_all"}>Open all notification</a></span>
                        </div>
                        : ''
                }
                {
                    NotificationHeaderData.length > 0 ?

                        <section aria-describedby="dialog-body-id-8" aria-labelledby="dialog-heading-id-8" className="cus-noti-section slds-popover slds-popover_large slds-nubbin_top-right absolute-positioned" role="dialog" style={styles.notification}>
                            <a href={"#"} className="slds-float_right all_read"
                                onClick={() => this.markAllNotificationAsRead(this.state.member_id)}
                            >Mark all as read</a>
                            <header className="slds-popover__header">
                                <h2 className="slds-text-heading_small" id="dialog-heading-id-8">Notifications</h2>
                            </header>
                            <div className="slds-popover__body slds-p-around_none" id="dialog-body-id-8">
                                <ul onScroll={this.handleScroll}>
                                    {
                                        NotificationHeaderData.map((item, index) => (
                                            <li className={`slds-global-header__notification ${this.props.IsAllRead === false ? item.unread === "1" ? 'slds-global-header__notification_unread' : '' : ''}`}>
                                                <button class="slds-button slds-button_icon slds-button_icon-container slds-notification__close" title={"Dismiss - " + item.title + " notification"} onClick={() => this.dismissNotification(item.id, index)}>
                                                    <svg aria-hidden="true" class="slds-button__icon">
                                                        <use href="/assets/salesforce-lightning-design-system/assets/icons/utility-sprite/svg/symbols.svg#close">
                                                        </use>
                                                    </svg>
                                                    <span class="slds-assistive-text">Dismiss {item.title}</span>
                                                </button>

                                                <div className="slds-media slds-has-flexi-truncate slds-p-around_x-small">
                                                    <div className="slds-media__figure">
                                                        <span className="slds-avatar slds-avatar_small">
                                                            <Icon
                                                                assistiveText={{ label: 'Account' }}
                                                                category="standard"
                                                                name={Number(item.entity_type) === 8 ? "date_input" : "avatar"}
                                                                size="small"
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="slds-media__body">
                                                        <div className="slds-grid slds-grid_align-spread">
                                                            <a href={"javascript:void(0);"}
                                                                className="slds-text-link_reset slds-has-flexi-truncate"
                                                                onClick={() => this.notificationRedirect(item, index,
                                                                    Number(item.entity_type) === 6 ? ROUTER_PATH + "admin/crm/serviceagreements/" + item.redirect_url_id :
                                                                        Number(item.entity_type) === 7 ? ROUTER_PATH + "admin/recruitment/application_quiz/detail/" + item.entity_id : Number(item.entity_type) === 8 ? ROUTER_PATH + "shift/" + item.entity_id : ""
                                                                )}
                                                            >
                                                                <h3 className="slds-truncate" title={item.title}>
                                                                    <strong>{item.title}</strong>
                                                                </h3>
                                                                <p className="slds-truncate" title={item.shortdescription}>{item.shortdescription}</p>
                                                                <p className="slds-m-top_x-small slds-text-color_weak">{moment(item.created).fromNow()}
                                                                    <abbr className="slds-text-link slds-m-horizontal_xxx-small" title="unread">{item.unread === "1" ? "●" : ""}</abbr>
                                                                </p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    }
                                    <li>
                                        <div style={{ position: 'relative', height: '5rem', display :
                                            this.state.isLoading ? 'block' : 'none'}}>
                                            <Spinner
                                                size="small"
                                                variant="base"
                                                assistiveText={{ label: 'Main Frame Loading...' }}

                                            />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <header className="slds-popover__header cus-footer">
                                <a href={ROUTER_PATH + "app/notification_all"} className="all_read">View All</a>
                            </header>
                        </section>
                        :
                        ''
                }
            </React.Fragment>
        );
    }

    renderPushMenu() {
        const { AllPermission } = this.props

        return (
            <div >
                <GlobalMenu propsData={this.props} isMember={this.state.showMemberTab} isActiveMember={this.state.isActiveMember}/>
            </div>
        )
    }

    /**
     * Render humburger menu if screen width less than 768
     */
    renderMobileToggleMenu() {
        return (
            <div id='SidebarTogglerParent'>
                <span id="PushMenuCloseOverlay" onClick={() => this.setState({ isLeftInnerSidebarOpen: false })}></span>
                <button
                    type="button"
                    className="btn btn-humburger"
                    onClick={() => {
                        this.setState(p => ({ isLeftInnerSidebarOpen: !p.isLeftInnerSidebarOpen }))
                        this.props.handleLeftMenu();
                    }}
                >
                    {
                        this.props.isLeftInnerSidebarOpen === true ?
                            <Icon
                                assistiveText={{ label: 'User' }}
                                category="utility"
                                name="close"
                                size="small"
                            />
                        :
                            <i className="icon icon-menu"></i>
                    }

                </button>
            </div>
        )
    }

    render() {
        let NotificationHeaderData = this.state.NotificationHeaderData;
        return (
            <React.Fragment>
                <div className="overlay"></div>
                <WebsocketUserConnectionHandleData />
                <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                    <div className="dis-flex slds-header-mob-view">
                        { this.renderMobileToggleMenu() }
                        <GlobalHeader
                        logoSrc={process.env.PUBLIC_URL + "/assets/images/admin/ocs_logo.svg"}>
                            {/* <GlobalHeaderButton>
                                <a class="slds-button slds-button_icon slds-button_icon-x-small" href="/help/dashboard" title="Help">
                                        <Icon
                                            category="utility"
                                            name={"help"}
                                            size="x-small"
                                        />
                                </a>
                            </GlobalHeaderButton> */}
                            <GlobalHeaderNotifications
                            notificationCount={this.props.notification_unread_count}
                            popover={
                                <Popover
                                    id={this.props.NotificationCount === 0 ? "cus-header-notifications-readed" : "cus-header-notifications"}
                                    align={'bottom right'}
                                    ariaLabelledby="header-notifications-custom-popover-content"
                                    body={this.renderHeaderNotificationsContent(NotificationHeaderData)}
                                />
                            }
                            />
                            <GlobalHeaderProfile
                                popover={
                                    <Popover
                                        body={this.renderProfileContent()}
                                        id="header-profile-popover"
                                    />
                                }
                                userName={getFullName()}
                                avatar={
                                    <Avatar
                                        assistiveText={{ icon: 'Avatar image' }}
                                        imgSrc={getAvatar() || DefaultPic}
                                        imgAlt="Profile Pic"
                                        size="medium"
                                        title={false}
                                    />
                                }
                            />
                        </GlobalHeader>
                    </div>
                </IconSettings>
                {this.renderPushMenu()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    NotificationType: state.NotificationReducer.NotificationType,
    NotificationCount: state.NotificationReducer.NotificationCount,
    IsAllRead: state.NotificationReducer.IsAllRead,
    NotificationHeaderData: state.NotificationReducer.NotificationHeaderData,
    notification_unread_count: state.NotificationReducer.notificationUnreadCount,
})

const mapDispatchtoProps = (dispach) => {
    return {
        notificationtoggle: (object) => dispach(setNotificationToggel(object)),
        notificationheaderalert: (object) => dispach(setNotificationHeaderAlert(object)),
        imailnotificationalert: (object) => dispach(setNotificationImailAlert(object)),
        onToggleInfographicSidebar: (object) => dispach(setToggleInfographicSidebar(object)),
        onClickDismissNotification: (index) => dispach(dismissNotificationAlert(index)),
        onClickMarkAllAsRead: (index) => dispach(markAllAsRead(index)),
        notificationRedirect: (index) => dispach(updateNotificationAsReaded(index)),
        setMemberFlag: (object) => dispach({ type: 'SET_MEMBER_FLAG' }),
        getUnreadNotificationCountact: (object) => dispach(getUnreadNotificationCount(object)),
    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(Header);