import React from 'react';
import { postData } from '../../../../../service/common';

/**
 * @typedef {typeof AttachmentCard.defaultProps} Props
 * 
 * Card to display uploaded attachments
 * 
 * @extends {React.Component<Props>}
 */
class MealTimeCard extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        not_applicable: false,
        assistance_plan_requirement: '',
        physical_assistance: false,
        aids: false,
        verbal_prompting: false,
        mealtime_assistance_plan: 0,
        physical_assistance_desc: '',
        aids_desc: '',
        verbal_prompting_desc: '',
      }
    }
  
    componentDidMount() {
      if(this.props.need_assessment_id){
               this.getSelectedMealAssistance(this.props.need_assessment_id);
      }
    }
  
    getSelectedMealAssistance = (id)=>{       
      postData("sales/NeedAssessment/get_selected_meal_assistance", {need_assessment_id:id}).then((res) => {
          if (res.status) {
              this.setState(res.data)
          }
      });    
      }
  
      empty_desc = () =>{
        if(this.state.physical_assistance==false){
          this.setState({physical_assistance_desc:''});
        }else if(this.state.verbal_prompting==false){
          this.setState({verbal_prompting_desc:''});
        }else if(this.state.aids==false){
          this.setState({aids_desc:''});
        }
      }
  
    render() {
      return (
        <React.Fragment>
          <div className="slds-grid">
            <div className="slds-panel slds-size_full slds-is-open" aria-hidden="false">
              <form id="mealtime_form" autoComplete="off" className="col-md-12 slds_form">
                <div className="slds-panel__header">
                  <h2 className="slds-panel__header-title slds-text-heading_small slds-truncate" title="Panel Header">Mealtime Assistance</h2>
                </div>
                <div className="slds-panel__body">
  
                  <fieldset className="slds-form-element">
                    {
                      (this.state.risk_aspiration == 2 || this.state.risk_choking == 2) ?
                        <label for="mealtime_assistance_plan" class="error CheckieError" style={{ display: "block", width: "20%", marginBottom: '0px' }}></label>
                        : ''
                    }
                    <legend className="slds-form-element__legend slds-form-element__label">
                      {
                        (this.state.risk_aspiration == 2 || this.state.risk_choking == 2) ?
                          <abbr class="slds-required" title="required">*</abbr>
                          : ''
                      }
                       Do you have a mealtime assistance plan ?</legend>
                    <div className="slds-form-element__control">
                      <span className="slds-radio slds-float_left">
                        <input type="radio" value="1" id="assisstance_plan_no" required name="mealtime_assistance_plan" disabled required={(this.state.risk_aspiration == 2 || this.state.risk_choking == 2) ? true : false}  checked={(this.state.mealtime_assistance_plan && this.state.mealtime_assistance_plan == 1) ? true : false} />
                        <label className="slds-radio__label" htmlFor="assisstance_plan_no">
                          <span className="slds-radio_faux"></span>
                          <span className="slds-form-element__label">No</span>
                        </label>
                      </span>
                      <span className="slds-radio slds-float_left">
                        <input type="radio" value="2" id="assisstance_plan_yes" name="mealtime_assistance_plan" disabled checked={(this.state.mealtime_assistance_plan && this.state.mealtime_assistance_plan == 2) ? true : false} />
                        <label className="slds-radio__label" htmlFor="assisstance_plan_yes">
                          <span className="slds-radio_faux"></span>
                          <span className="slds-form-element__label">Yes</span>
                        </label>
                      </span>
                    </div>
                  </fieldset>
                  {
                    (this.state.mealtime_assistance_plan && this.state.mealtime_assistance_plan === '1') ?
                      <fieldset className="slds-form-element mb-3">
                        <div className="col-md-6">
                          <div className="slds-form-element__control">
                            <div className="SLDS_date_picker_width">
                               {this.state.assistance_plan_requirement}
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      : <React.Fragment />
                  }
  
                  <fieldset className="slds-form-element">
                    <legend className="slds-form-element__legend slds-form-element__label"> Risk of aspiration</legend>
                    <div className="slds-form-element__control">
                      <span className="slds-radio slds-float_left">
                        <input type="radio" id="aspiration_no" value="1" name="risk_aspiration" disabled checked={(this.state.risk_aspiration && this.state.risk_aspiration == 1) ? true : false} />
                        <label className="slds-radio__label" htmlFor="aspiration_no">
                          <span className="slds-radio_faux"></span>
                          <span className="slds-form-element__label">No</span>
                        </label>
                      </span>
                      <span className="slds-radio slds-float_left">
                        <input type="radio" id="aspiration_yes" value="2" name="risk_aspiration" disabled checked={(this.state.risk_aspiration && this.state.risk_aspiration == 2) ? true : false} />
                        <label className="slds-radio__label" htmlFor="aspiration_yes">
                          <span className="slds-radio_faux"></span>
                          <span className="slds-form-element__label">Yes</span>
                        </label>
                      </span>
                    </div>
                  </fieldset>
  
                  <fieldset className="slds-form-element">
                    <legend className="slds-form-element__legend slds-form-element__label"> Risk of choking</legend>
                    <div className="slds-form-element__control">
                      <span className="slds-radio slds-float_left">
                        <input type="radio" id="choking_no" value="1" name="risk_choking" disabled checked={(this.state.risk_choking && this.state.risk_choking == 1) ? true : false} />
                        <label className="slds-radio__label" htmlFor="choking_no">
                          <span className="slds-radio_faux"></span>
                          <span className="slds-form-element__label">No</span>
                        </label>
                      </span>
                      <span className="slds-radio slds-float_left">
                        <input type="radio" id="choking_yes" value="2" name="risk_choking" disabled checked={(this.state.risk_choking && this.state.risk_choking == 2) ? true : false} />
                        <label className="slds-radio__label" htmlFor="choking_yes">
                          <span className="slds-radio_faux"></span>
                          <span className="slds-form-element__label">Yes</span>
                        </label>
                      </span>
                    </div>
                  </fieldset>
                  <fieldset className="slds-form-element">
                    <legend className="slds-form-element__legend slds-form-element__label"> Do you require assistance with meal time ?</legend>
                    <div className="slds-form-element__control">
                      <span className="slds-radio slds-float_left">
                        <input type="radio" id="require_assistance_no" value="1" name="require_assistance_plan"  checked={(this.state.require_assistance_plan && this.state.require_assistance_plan == 1) ? true : false} disabled />
                        <label className="slds-radio__label" htmlFor="require_assistance_no">
                          <span className="slds-radio_faux"></span>
                          <span className="slds-form-element__label">No</span>
                        </label>
                      </span>
                      <span className="slds-radio slds-float_left">
                        <input type="radio" id="require_assistance_yes" value="2" name="require_assistance_plan" checked={(this.state.require_assistance_plan && this.state.require_assistance_plan == 2) ? true : false} disabled />
                        <label className="slds-radio__label" htmlFor="require_assistance_yes">
                          <span className="slds-radio_faux"></span>
                          <span className="slds-form-element__label">Yes</span>
                        </label>
                      </span>
                    </div>
                  </fieldset>
                 
                  {this.state. require_assistance_plan==2 ?
                  <div> <div className="slds-form-element__control row">
                  <span className="slds-checkbox slds-float_left col col-sm-6">
                    <input type="checkbox" name="physical_assistance" id="physical_assistance"  checked={(this.state.physical_assistance && this.state.physical_assistance == '1') ? true : false} />
                    <label className="slds-checkbox__label" htmlFor="physical_assistance">
                      <span className="slds-checkbox_faux"></span>
                      <span className="slds-form-element__label"> Physical Assistance</span>
                    </label>
                  </span>
                </div>
                {this.state.physical_assistance && <div className="slds-form-element__control row">
                  <fieldset className="slds-form-element mb-3">
                    <div className="col-md-6"><label className="slds-form-element__legend slds-form-element__label">Description</label>
                      <div className="slds-form-element__control">
                        <div className="SLDS_date_picker_width">
                         {this.state.physical_assistance_desc}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>}
  
                <div className="slds-form-element__control row">
                  <span className="slds-checkbox slds-float_left col col-sm-6">
                    <input type="checkbox" name="verbal_prompting" id="verbal_prompting" checked={(this.state.verbal_prompting && this.state.verbal_prompting == '1') ? true : false} />
                    <label className="slds-checkbox__label" htmlFor="verbal_prompting">
                      <span className="slds-checkbox_faux"></span>
                      <span className="slds-form-element__label"> Verbal Prompting/Reminding</span>
                    </label>
                  </span>
                </div>
  
                {this.state.verbal_prompting && <div className="slds-form-element__control row">
                  <fieldset className="slds-form-element mb-3">
                    <div className="col-md-6"><label className="slds-form-element__legend slds-form-element__label">Description</label>
                      <div className="slds-form-element__control">
                        <div className="SLDS_date_picker_width">
                        {this.state.verbal_prompting_desc}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>} 
  
                <div className="slds-form-element__control row">
                  <span className="slds-checkbox slds-float_left col col-sm-6">
                    <input type="checkbox" name="aids" id="aids"  checked={(this.state.aids && this.state.aids == '1') ? true : false} />
                    <label className="slds-checkbox__label" htmlFor="aids">
                      <span className="slds-checkbox_faux"></span>
                      <span className="slds-form-element__label"> Aids</span>
                    </label>
                  </span>
                </div>
  
                {this.state.aids && <div className="slds-form-element__control row">
                  <fieldset className="slds-form-element mb-3">
                    <div className="col-md-6"><label className="slds-form-element__legend slds-form-element__label">Description</label>
                      <div className="slds-form-element__control">
                        <div className="SLDS_date_picker_width">
                        {this.state.aids_desc}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div> }</div> : ''}
                
                  
  
                </div>                
              </form>
            </div>
          </div>
        </React.Fragment >
      );
    }
  }

export default MealTimeCard